/* You can add global styles to this file, and also import other style files */
@import "~@fortawesome/fontawesome-svg-core/styles.css";
/*@import "~@progress/kendo-theme-default/dist/all.scss";*/

body {
  /*color: #494949;*/
  color: #000000;
/*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;*/
  font-family: Roboto;
  font-weight: 300;
  font-size: 1rem;
  margin: 0;
}

html,
body {
  height: 100%;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.font-20 {
  font-size: 20px !important;
}

.Title {
  font-size: 20px;
  /*font-weight: 400;*/
  font-weight: 500;
  color: #494949;
  align-items: center;
  margin: 0px;
}

.capitalize {
  text-transform: capitalize;
}

.navbar {
  padding: 0rem;
}

body a {
  /*color: #ec1b24;*/
  /*color: #2151A2;*/
  color: #1d53a4;
}

a {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.btn {
  background-clip: padding-box;
  border-radius: 1px;
  padding: 6px 9px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 13px;
  line-height: 1.4285714286;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
}

.btn-primary {
/*  background-color: #c70000 !important;
  border-color: #c70000 !important;*/
/*  background-color: #2151a2 !important;
  border-color: #2151a2 !important;*/
  background-color: #1d53a4 !important;
  border-color: #1d53a4 !important;
  border-radius: 0px !important;
  background-clip: border-box;
}

.btn-secondary {
  border-color: rgba(0, 0, 0, 0.08);
  color: #6d6d6d !important;
  background-color: #f6f6f6;
  font-weight: 300;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #e0e0e0 !important;
  border-color: #cccccc !important;
  color: #505050 !important;
  box-shadow: none !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #f6f6f6 !important;
  border-color: #e2e2e2;
  cursor: default;
}

.btn-link {
  font-weight: 300;
  color: #494949;
}

.btn-light-gray {
  color: #4c4c4c;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
  box-shadow: none;
  background-image: none !important;
}

.btn-grid-actions {
  min-width: 38px;
  margin: 0.3em;
  margin-left: 0px;
}

.form-control {
  border-radius: 0px !important;
}

.internal-page-title {
  font-size: 18px;
  font-weight: 300;
}

.page-title-box {
  border-bottom: 1px solid #e4dada;
  height: 69px;
}

.internal-title-box {
  border-bottom: 1px solid #e4dada;
  height: 35px;
  padding-left: 0px;
  background-color: #fff;
}

.internal-title {
  font-size: 0.95rem;
  font-weight: 500;
}

.k-grid-header-wrap th {
  font-weight: 500 !important;
  text-align: center;
}

.k-grid th {
  padding: 8px 3px;
}

.k-grid .k-grid-content td a:hover {
  text-decoration: underline !important;
}

.k-loading-image {
  color: #313131;
}

.k-grid-content,
.k-grid table {
  min-height: 80px;
}

quill-editor:focus {
  outline: none;
}

.ql-snow .ql-tooltip {
  z-index: 9999 !important;
}

.ql-toolbar.ql-snow {
  padding: 5px 0px !important;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border-color: #e4e4e4 !important;
}

.item-initial-container {
  color: #FFFFFF;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.2625px;
}

.item-avatar-container {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #570453;
  float: left;
  margin-right: 5px;
}

.item-text-container {
  margin-left: 40px;
}

.tooltip-inner {
  max-width: 500px !important;
  padding: 3px 8px;
  color: #FFF;
  text-align: left;
  background-color: #3d3939 !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 0.80rem;
  margin-top: -5px;
}

.arrow {
  display: none !important;
}

@media screen and (max-width: 1550px) {

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    width: 18px !important;
    height: 21px !important;
  }

  .ql-snow .ql-picker {
    font-size: 11px !important;
    /*font-weight: 400 !important;*/
    font-weight: 500 !important;
    height: 19px !important;
  }

  .ql-snow .ql-color-picker,
  .ql-snow .ql-icon-picker {
    width: 24px !important;
    height: 21px !important;
  }

  .ql-snow .ql-picker.ql-size {
    width: 90px !important;
  }

}

/* Show Catalog image and title */
.container {
  position: relative;
  width: 50%;
}

.k-image {
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .k-image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #5d5d5d;
  color: white;
  font-size: 13px;
  padding: 5px 15px;
}

/* end - Show Catalog image and title */

#catalogView .k-i-collapse::before {
  content: "\2212" !important;
}

#catalogView .k-i-expand::before {
  content: "\002b" !important;
}

#catalogView.k-treeview {
  padding: 10px;
  width: auto;
}

#catalogView.k-treeview .k-in.k-state-focused {
  box-shadow: none;
}

#catalogView.k-treeview>kendotreeviewitemcontent {
  display: contents;
}

kendo-treeview#catalogView>ul.k-group:first-child {
  border: 1px solid #c0bbbb !important;
  margin: 10px;
}

kendo-treeview#catalogView>ul:first-child>li.k-item>div.k-mid:first-child {
  padding: 10px 0px 0px 0px;
}

kendo-treeview#catalogView>ul:first-child>li.k-item {
  padding: 0px;
}

  kendo-treeview#catalogView > ul:first-child > li.k-item > div.k-mid:first-child > span {
    /*font-weight: 400;*/
    font-weight: 500;
    font-size: 19px;
    /*display: contents;*/
  }

.k-treeview .k-in {
  display: contents;
}

kendo-treeview#catalogView>ul>li.k-treeview-item>div.k-mid {
  border-bottom: 1px solid #c0bbbb;
  border-left: 0px;
  background: #ededed;
}

#catalogView.k-treeview .k-i-expand,
.k-treeview .k-i-collapse {
  margin-left: 5px;
}

kendo-treeview#catalogView>ul>li.k-treeview-item:last-child>div.k-mid {
  border-bottom: 0px;
  border-top: 1px solid #c0bbbb;
  background: #ededed;
}

#catalogView li.k-treeview-item div.k-mid {
  min-height: 80px;
}

#catalogView li.k-treeview-item>div.k-mid {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}

#catalogView .k-image {
  display: inline-block;
  width: 70px !important;
  height: 70px !important;
  background-size: 32px 35px;
  background-position: center center;
  background-repeat: no-repeat;
  vertical-align: middle;
  line-height: 32px;
  box-shadow: inset 0 0 1px #eaeaea, inset 0 0 10px rgba(0, 0, 0, .1);
  margin-left: 5px;
  mix-blend-mode: multiply;
  border: 1px solid #dcdcdc;
  padding: 5px;
  margin: 10px 0px;
}

.k-panel {
  display: flex !important;
}

li.sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 4;
}

/* move sort icon and number to left and shift text right to make room for them when a column has been resized */
.k-grid-header .k-header>.k-link {
  padding-right: 1.5em;
  position: relative;
}

.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-desc-sm,
.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-asc-sm {
  position: absolute;
  top: 50%;
  right: 0.4em;
  transform: translateY(-50%);
  margin-left: 0;
}

.k-grid-header .k-sort-order {
  position: absolute;
  right: 0.25em;
}

/*make distance between sort icon and filter icon*/
.k-grid-header .k-with-icon,
.k-grid-header .k-filterable {
  padding-right: calc(22px + 1.4285714286em);
}

.k-notification-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.k-notification-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.k-notification-group .k-notification {
  padding: 20px 15px;
  font-size: 15px;
  font-weight: 300;
}

.k-notification-content ul {
  padding-inline-start: 0px;
}

.k-notification-content ul li {
  line-height: 24px;
}

.k-notification-wrap .k-icon .k-i-error {
  font-size: 20px;
}

.k-notification-group {
  z-index: 1000;
}

.k-dialog-buttongroup {
  border: 0px !important;
}

  .k-dialog-buttongroup .k-button.k-primary {
    /*background-color: #c70000 !important;
    border-color: #c70000 !important;*/
    /*background-color: #2151a2 !important;
    border-color: #2151a2 !important;*/
    background-color: #1d53a4 !important;
    border-color: #1d53a4 !important;
    border-radius: 0px !important;
    background-clip: border-box;
    padding: 6px 10px;
    margin: 0px 10px 10px 3px;
    color: #fff !important;
  }

.k-dialog-buttongroup .k-button {
  padding: 6px 10px;
  margin: 0px 5px 10px 10px;
  border-color: rgba(0, 0, 0, 0.08);
  color: #6d6d6d !important;
  background-color: #f6f6f6 !important;
  font-weight: 300;
}

.k-dialog-titlebar {
  background-color: #f6f6f6;
  font-weight: 300;
  color: #656565;
}

.k-window-content,
.k-prompt-container {
  font-size: 17px;
  font-weight: 300;
}

.k-grid td.grid_checkbox {
  text-overflow: unset !important;
  padding: 8px 0px;
}

.k-grid-content {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadcrumb-item-link {
  color: #494949;
  padding: 3px 6px;
  border-radius: 3px;
}

.breadcrumb-item-link:hover {
  background: #e9e9e9 !important;
  text-decoration: none;
}

.internal-page-title .breadcrumb-item-link {
  color: #494949;
  padding: 3px 6px;
  border-radius: 3px;
}

.internal-page-title .breadcrumb-item-link:hover {
  background: #e9e9e9 !important;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

app-root, app-root > div {
    display: block;
    height: 100%;
}

div.row {
    height: 100%;
}


.page-title-box {
    font-weight: 500;
}

button.dialog-button {
    border: 0;
}

button.dialog-button:nth-child(2) {
    margin-left: 0.5rem;
}

/* Bootstrap row gutters were causing a horizontal scrollbar across the whole page.
   This is a quick fix to eliminate it, might need revisiting */
.row {
    --bs-gutter-x: 0 !important;
}

#catalog-detail-viewer #unity-container,
#catalog-detail-viewer .part-viewer {
    position: static !important;
}

ngx-masonry {
  width: 1100px !important;
}

/* css block for setting styles when printing */
@media print {
  app-navigation {
    display: none;
  }

  #catalog-viewport {
    left: 0 !important;
  }

  .catalog-breadcrumb {
    padding-top: 0 !important;
    height: 40px;
    line-height: 40px;
  }

  #catalog-header {
    background-color: #eee;
    height: 40px;
  }

  app-navigation {
    display: none !important;
    width: 0px !important;
  }

  #catalog-viewport {
    float: left;
    left: 0 !important;
  }

  .catalog-breadcrumb {
    height: 30px !important;
    line-height: 30px !important;
  }

    .catalog-breadcrumb span {
      padding-top: 0 !important;
    }

  #catalog-header {
    background-color: #eee !important;
    height: 30px !important;
  }

  .internal-page-title, .catalog-page-section-heading {
    font-size: 16px !important;
  }

  .catalog-page-title {
    font-size: 18px !important;
  }

  #catalog-header > div {
    padding-left: 0 !important;
  }

  .page-title-box {
    /*    border-bottom: 0;*/
  }

  .padded {
    padding: 0 !important;
  }

  .padded-sides {
    padding: 0 !important;
  }

  .catalog-page-title {
    margin: 0.25rem 0 0 0 !important;
  }

  #catalog-detail-viewer .grid-document {
    margin: 0.25rem !important;
    height: 182px !important;
    min-height: 182px !important;
    max-height: 182px !important;
  }

  .grid-document, .wayfinder-quantity, .wayfinder-document-count, .wayfinder-detail-icon {
    border: 1px solid rgb(220,220,220) !important;
  }

  #catalog-detail-viewer .grid-document-footer {
    display: none;
  }
  .room-content-documents { 
    display: none;
  }
  .property-card {
    margin: 5px !important;
    padding: 5px !important;
    /*width: 285px !important;*/
    box-shadow: none !important;
    font-size: 12px !important;
  }

  ngx-masonry {
    width: 900px !important;
    /*width: 100% !important;*/
  }

  .layout-content.show {
    margin-left: 0 !important;
  }

  #catalog-viewport {
    width: 100% !important;
    height: 100% !important;
  }

  app-footer {
    display: none;
    height: 0 !important;
  }

  .property-card table {
    /*width: 275px !important;
    max-width: 275px !important;*/
  }

  #catalog-content {
    height: auto !important;
    overflow-y: visible !important;
  }

  #main-documents, #main-documents-heading {
    display: none !important;
  }
  .toggle {
    display: none;
  }
}









.table-container {
  width: 100%;
  padding: 0 0 0 0.5rem;
  height: calc(100vh - 177px);
  overflow-y: auto;

  img {
    height: 90px;
    max-width: 83px;
  }

  td {
    padding: 0.5rem 0.5rem;
    font-size: 14px;
  }

    td span {
      white-space: normal;
      word-wrap: break-word;
    }

  th {
    font-size: 16px;
    font-weight: 500;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-image: url('/assets/images/table-header-bg.png');
    background-size: 100% 100%;
  }

  th, td {
    padding: 8px;
    text-align: left;
    /*  border: 1px solid #ddd;*/
  }
/*
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  */
  tr {
    border-bottom: 1px solid #E1DADA;
  }
  tr.dragging-over {
    background-color: #ddd !important;
  }

  tr.superseded {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
}

.multiple-lists .table-container {
  height: 250px !important;
}

.no-left-padding .table-container {
  padding: 0 !important;
}

.padded-bottom {
  padding-bottom: 1rem;
}